<nb-layout windowMode>
    <nb-layout-header fixed>
      <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
      <ng-content select="nb-menu"></ng-content>
    </nb-sidebar>

    <nb-layout-column>
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
</nb-layout>

<div *ngIf="state?.pageLoading$ | async" class="full-screen-blur">
  <div class="loader-container">
    <p class="loader-text glow">Loading...</p>
  </div>
</div>