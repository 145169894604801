import { Component, OnInit } from '@angular/core';
import { NbIconLibraries, NbThemeService } from '@nebular/theme';
import { STORAGE_KEY_THEME, STORAGE_KEY_LANGUAGE } from './models/storage/local-storage.models';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-app',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private themeService: NbThemeService,
    public translate: TranslateService,
    private iconLibraries: NbIconLibraries,
    private router: Router
  ) {
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    if (environment.build.env == 'local' || environment.build.env == 'test') {
      favIcon.href = '/assets/images/Logo_Lumosa_2021_red.svg';
    } else if (environment.build.env == 'qa' || environment.build.env == 'acceptance') {
      favIcon.href = '/assets/images/Logo_Lumosa_2021_blue.svg';
    }

    /* List available languages and set default */
    translate.addLangs(['en', 'nl', 'pt', 'zh_Hans']);
    translate.setDefaultLang('en');

    /* Check for and set preferred language */
    var prefLang = localStorage.getItem(STORAGE_KEY_LANGUAGE);
    if (prefLang != null) {
      translate.use(prefLang);
    }

    /* Register fontawesome icon pack */
    this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.setDefaultPack('font-awesome');
  }

  ngOnInit(): void {
    const theme = localStorage.getItem(STORAGE_KEY_THEME) ?? 'default';
    this.themeService.changeTheme(theme);
  }
}
