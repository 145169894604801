import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter, tap, timeout } from 'rxjs/operators';
import { StateService } from '../../state/state.service';

const REQUEST_TIMEOUT_MS = 10000;

@Injectable()
export class LoadingInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private state: StateService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.state.pageLoading$.next(true);

    return next.handle(request).pipe(
      filter(event => event.type !== 0),
      tap(event => {
        if (event instanceof HttpResponse) {
          this.state.pageLoading$.next(false);
        }
      }),
      timeout(REQUEST_TIMEOUT_MS),
      catchError((httpResponse: HttpErrorResponse) => {
        this.state.pageLoading$.next(false);
        if (httpResponse.error) {
          throw(httpResponse.error);
        } else {
          throw({
            code: 500,
            message: 'Unknown error occured',
          });
        }
      }),
    );
  }
}
