import { Injectable } from '@angular/core';
import { DocumentNode, WatchQueryFetchPolicy } from '@apollo/client/core';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GraphqlPagedData, GraphqlQueryOptions } from './graphql.models';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';
import { GQL_QUERY_AUTHORIZATION_ACTIONS_ALLOWED, GQL_QUERY_AUTHORIZATION_ALL_ACTIONS } from './query/graphql.query.authorization.actions';
import { LdkGraphqlApiService, GraphqlError } from '@lumosa/ui-sdk/services';
import { HttpStatusCode } from '@angular/common/http';
import { PageRoutes } from 'app/models/page-routes.models';
import { STORAGE_KEY_TOKEN } from 'app/models/storage/local-storage.models';

const DEFAULT_QUERY_CACHE_POLICY: WatchQueryFetchPolicy = 'network-only';
const DEFAULT_MUTATION_CACHE_POLICY: MutationFetchPolicy = 'network-only';

@Injectable({
  providedIn: 'root',
})
export class GraphqlApiService {
  constructor(
    private gqlClient: LdkGraphqlApiService,
    private router: Router,
    private notify: NbToastrService,
    private translate: TranslateService
  ) {
    gqlClient.errors$.pipe(
      tap((error: GraphqlError) => {
        const { code, message } = error;
        if (code === HttpStatusCode.Unauthorized || code === HttpStatusCode.Forbidden) {
          // Token expired, kick to login screen. This only should happen if page is closed when token expires  
          this.router.navigateByUrl(PageRoutes.Login);
          localStorage.setItem(STORAGE_KEY_TOKEN, '');
        } else {
          console.warn(error);
          this.notify.danger(
            message != null ? message : this.translate.instant('GENERAL.NOTIFY.MSG.ERROR.SYSTEM'),
            this.translate.instant('GENERAL.NOTIFY.TITLE.ERROR'),
          );
        }
      })
    )
      .subscribe();
  }

  /**
   * Execute a mutation query
   * @param options All needed variable to execute the mutation
   * @returns Observable
   */
  mutation<T>(options: {
    query: DocumentNode,
    variables: unknown,
    fetchPolicy?: MutationFetchPolicy,
    returnedAsName?: string
  }): Observable<T> {
    const { query: mutation, fetchPolicy = DEFAULT_MUTATION_CACHE_POLICY, variables = null, returnedAsName = null } = options;
    return this.gqlClient.mutation<T>({
      fetchPolicy,
      mutation,
      variables,
      returnedAsName
    });
  }

  /**
   * Execute given paged query, for use one paged table view
   * @param input All needed variable to execute the query
   * @returns Observable
   */
  pagedQuery<T>(input: { query: DocumentNode; variables: GraphqlQueryOptions; returnedAsName: string; fetchPolicy?: WatchQueryFetchPolicy; }): Observable<GraphqlPagedData<T>> {
    return this.gqlClient.pagedQuery<T>({
      query: input?.query,
      fetchPolicy: input?.fetchPolicy ?? DEFAULT_QUERY_CACHE_POLICY,
      gqlFilterOptions: input.variables,
      returnedAsName: input.returnedAsName
    });
  }

  /**
   * Execute given query 
   * @param options All needed variable to execute the query
   * @returns Observable
   */
  query<T>(options: {
    query: DocumentNode,
    variables?: unknown,
    cachePolicy?: WatchQueryFetchPolicy,
    returnedAsName?: string
  }): Observable<T> {
    if (!options.variables) options.variables = {};
    if (!options.cachePolicy) options.cachePolicy = DEFAULT_QUERY_CACHE_POLICY;
    const { query, variables, cachePolicy, returnedAsName = null } = options;
    return this.gqlClient.query<T>({
      query,
      variables,
      fetchPolicy: cachePolicy,
      returnedAsName
    });
  }

  /**
   * A generic query to delete an item in any collection
   * @param id Identifier of item
   * @param query Query that will be executed
   * @returns Observable
   */
  deleteItem(id: string, query: DocumentNode): Observable<number> {
    return this.gqlClient.mutation<number>({
      fetchPolicy: DEFAULT_MUTATION_CACHE_POLICY,
      mutation: query,
      variables: { id },
      returnedAsName: 'count'
    });
  }

  /**
   * Get all available actions
   * @returns List of strings
   */
  allActions() {
    return this.gqlClient.query({
      query: GQL_QUERY_AUTHORIZATION_ALL_ACTIONS,
      fetchPolicy: DEFAULT_QUERY_CACHE_POLICY,
      returnedAsName: 'actions'
    });
  }

  /**
   * Get all actions allowed for the logged in user
   * @returns List of string
   */
  allAllowedActions() {
    return this.gqlClient.query({
      query: GQL_QUERY_AUTHORIZATION_ACTIONS_ALLOWED,
      fetchPolicy: DEFAULT_QUERY_CACHE_POLICY,
      returnedAsName: 'allowed'
    });
  }
}