import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { Observable, Subscriber, of } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { LdkNavigationBarComponent } from '@lumosa/ui-sdk/components/navigation-bar';

const LOGOUT = 'logout';
const IMAGE_URL = 'https://graph.microsoft.com/v1.0/me/photo/$value';

@UntilDestroy()
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @ViewChild('navbar') navbar!: LdkNavigationBarComponent;

  userPictureOnly: boolean = false;
  lumosaLogoOnly: boolean = false;
  user: { name: string; picture?: string; email?: string; };

  title = 'Lumosa Portal';
  envName = environment.build?.env;
  production = environment.production;
  userMenu: NbMenuItem[];

  sideMenuSettings: { name: string, fullCollapseOn: boolean } = {
    name: 'menu-sidebar',
    fullCollapseOn: true
  }

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService) {
  }

  ngOnInit() {

    // Watch for language change, update logout button if triggered
    this.translate.onLangChange
      .pipe(
        untilDestroyed(this),
        tap(_ => this.navbar.userMenu[0].title = this.translate.instant('LOGOUT'))
      )
      .subscribe();

    this.auth.user.pipe(
      untilDestroyed(this),
      filter(x => x !== null),
      tap(x => {
        const { name, email } = x;
        this.user = {
          ...this.user,
          name,
          email
        }
      })
    ).subscribe();

    this.getUserImage()
      .pipe(
        untilDestroyed(this),
        mergeMap(blob => this.blobToBase64(blob)),
        tap((image: string) => {
          if (image != null) {
            this.user = {
              ...this.user,
              picture: image
            };
          }
        }),
        catchError(_ => {
          return of('error fetching image')
        })
      ).subscribe();
  }


  menuItemClicked(action: string) {
    if (action == LOGOUT) {
      this.auth.clearAuthorization();
      this.router.navigateByUrl('/auth');
    }
  }

  getUserImage(): Observable<Blob> {
    return this.http.get(IMAGE_URL, { responseType: 'blob' });
  }

  private blobToBase64(blob): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Observable((observer: Subscriber<any>): void => {
      reader.onloadend = (ev: ProgressEvent<FileReader>): void => {
        observer.next(reader.result);
        observer.complete();
      }
      reader.onerror = (error: any): void => {
        observer.error(error);
      }
    });
  }
}
