import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbBadgeModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { LdkNavigationBarModule } from '@lumosa/ui-sdk/components/navigation-bar';
import { OneColumnLayoutComponent } from './one-column/one-column.layout';
import { HeaderComponent } from './header/header.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbBadgeModule
];
const COMPONENTS = [
  HeaderComponent,
  OneColumnLayoutComponent,
];

const LDK_MODULES = [
  LdkNavigationBarModule
]

@NgModule({
  imports: [CommonModule, ...NB_MODULES, ...LDK_MODULES],
  exports: [CommonModule, ...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'lumosa-light',
          },
        ).providers,
      ],
    };
  }
}
