import { Component } from '@angular/core';
import { StateService } from '../../services/state/state.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent {

  constructor(
    public state: StateService,
  ) {}
}
