import { gql } from 'apollo-angular';

export const GQL_QUERY_AUTHORIZATION_ROLES_PATHS = gql`
    query allowedPaths {
        paths: allowedPaths
    }
`
export const GQL_QUERY_AUTHORIZATION_PATH_ALLOWED = gql`
    query checkPathAuthorization($path: String) {
        allowed: checkPathAuthorization(path: $path)
    }  
`

export const GQL_QUERY_AUTHORIZATION_PATH_ACTIONS = gql`
    query checkPathActions($path: String){
        permissions: checkPathActions(path: $path)
    }
`
