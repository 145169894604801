import { gql } from 'apollo-angular';

export const GQL_QUERY_AUTHORIZATION_ALL_ACTIONS = gql`
    query actions{
        actions : actions {
            id
            action,
            description,
            roles
        }
    }
`;

export const GQL_QUERY_AUTHORIZATION_ACTIONS_ALLOWED = gql`
    query getAllowedActions{
        allowed: allowedActions
    }
`;